<template>
  <div class="menu-manage">
    <div class="head">
      <el-button size="small" type="primary" @click="onAddEdit('add')">新增</el-button>
      <el-button size="small">恢复默认</el-button>
    </div>
    <div class="content">
      <el-table :data="tbody" v-loading="tableLoading" style="width: 100%" class="thead-light" stripe
        row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column v-for="th in thead" :key="th.prop" :label="th.label" :prop="th.prop"
          :width="th.width" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button size="small" type="text" @click="onAddEdit('edit',row)">编辑</el-button>
              <el-button size="small" type="text" @click="onDelete(row.id)">删除</el-button>
            </template>
            <!-- 图标 -->
            <div v-else-if="th.prop==='menu_icon'" class="icon-img">
              <img :src="row.menu_icon" />
            </div>
            <!-- 启用 -->
            <el-checkbox v-else-if="th.prop==='enable'" v-model="row.enable"
              @change="onTaggleEnable(row.id,row.enable)"></el-checkbox>
            <!-- 其他 -->
            <span v-else>{{row[th.prop]}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增编辑弹窗 -->
    <add-edit-dialog v-model="openAddEditDialog" :type="addEditType" :data="addEditData" />
    <!-- 删除弹窗 -->
    <delete-dialog v-model="openDeleteDialog" :delete-id="deleteId" />
  </div>
</template>
<script>
import { menuList } from "../api/menu";
import AddEditDialog from "../components/menu/AddEditDialog";
import DeleteDialog from "../components/menu/DeleteDialog";
export default {
  data() {
    return {
      tableLoading: false, //加载中
      thead: [
        { label: "折叠", prop: "expand", width: 100 },
        { label: "排序", prop: "order" },
        { label: "菜单名称", prop: "name", width: 200 },
        { label: "菜单图标", prop: "menu_icon" },
        { label: "节点名称", prop: "node_name", width: 200 },
        { label: "菜单层级", prop: "menu_level", width: 200 },
        { label: "启用", prop: "enable" },
        { label: "操作", prop: "operate", width: 120, fixed: "right" },
      ],
      tbody: [],
      addEditType: "", //弹窗新增或者编辑模式
      openAddEditDialog: false, //显示新增编辑弹窗
      addEditData: {}, //编辑弹窗的数据
      openDeleteDialog: false, //显示删除弹窗
      deleteId: "", //删除的数据id
    };
  },
  methods: {
    //获取列表数据
    getMenuList() {
      this.tableLoading = true;
      menuList()
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          // console.log(data);
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
        });
    },
    //启用
    onTaggleEnable(id, status) {
      console.log(id, status);
      this.getMenuList();
    },
    //新增或者编辑
    onAddEdit(type, data) {
      switch (type) {
        case "add":
          this.addEditData = {}; //防止使用历史数据
          this.addEditType = type;
          break;
        case "edit":
          this.addEditData = data;
          this.addEditType = type;
          break;
      }
      this.openAddEditDialog = true;
    },
    //删除
    onDelete(id) {
      this.deleteId = id;
      this.openDeleteDialog = true;
    },
  },
  created() {
    //获取列表数据
    this.getMenuList();
  },
  components: {
    AddEditDialog,
    DeleteDialog,
  },
};
</script>
<style lang="scss" scoped>
.menu-manage {
  .head {
    margin-bottom: 32px;
    ::v-deep.el-button {
      & + .el-button {
        margin-left: 20px;
      }
    }
  }
  .content {
    .icon-img {
      height: 30px;
      img {
        height: 100%;
      }
    }
  }
}
</style>