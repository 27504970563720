<template>
  <el-dialog
    :visible.sync="show"
    :title="dialogTitle"
    width="504px"
    class="dialog-vertical"
  >
    <div class="content">
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="70px"
        size="medium"
        class="small-form"
        @submit.native.prevent
      >
        <!-- 菜单名称 -->
        <el-form-item label="菜单名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入菜单名称"></el-input>
        </el-form-item>
        <!-- 菜单层级 -->
        <el-form-item label="菜单层级：" prop="menu_level">
          <el-select v-model="form.menu_level" placeholder="请选择菜单层级">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <!-- 对应节点 -->
        <el-form-item label="对应节点：" prop="node_name">
          <el-select v-model="form.node_name" placeholder="请选择对应节点">
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
          <div class="info">
            <i class="el-icon-info"></i>二级菜单不能选择一级节点
          </div>
        </el-form-item>
        <!-- 显示隐藏 -->
        <el-form-item label="菜单：" prop="enable">
          <el-radio-group v-model="form.enable">
            <el-radio :label="true">显示</el-radio>
            <el-radio :label="false">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 菜单图标 -->
        <el-form-item label="菜单图标：" prop="menu_icon">
          <div class="info">
            <i class="el-icon-info"></i>上传尺寸为20*20
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show = false">取消</el-button>
      <el-button size="small" type="primary">保存</el-button>
    </template>
  </el-dialog>
</template>
<script>
export default {
  model: {
    prop: "open",
    event: "changeOpen",
  },
  props: {
    //是否显示
    open: Boolean,
    //模式：add（新增）、edit（编辑）
    type: {
      type: String,
      default: "add",
    },
    //编辑时使用的数据
    data: Object,
  },
  data() {
    return {
      form: {
        name: "", //菜单名称
        menu_level: "", //菜单层级
        node_name: "", //对应节点
        enable: false, //显示或者隐藏
        menu_icon: "", //菜单图标
      },
      rules: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("changeOpen", val);
      },
    },
    dialogTitle() {
      return this.type === "add"
        ? "新增"
        : this.type === "edit"
        ? "编辑"
        : "其他";
    },
  },
};
</script>
<style lang="scss" scoped>
// .dialog-vertical {
//   .content {
//   }
// }
</style>
